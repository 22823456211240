<template>
  <div class="app-container">


    <Search :searchOpts="searchOpts" :listTypeInfo="listTypeInfo" @onSearch="customOnSearch" @onReset="customOnReset"/>


    <TableToolBar :url="url" :single="single" :multiple="multiple" :handleAdd="customhandleAdd"
                  :handleDelete="handleDelete"
    />

    <div class="t-table" style="width:100%;">
      <t-table :table="table"
               isShowPagination
               :columns="columns"
               :listTypeInfo="listTypeInfo"
               @selection-change="selectionChange"
               @page-change="pageChange"
      >
      </t-table>
    </div>


    <t-dialog :title="formOpts.title" :visible.sync="open" width="950px" :isShowDialogDrag="true"  append-to-body>
      <t-form
        :ref-obj.sync="formOpts.ref"
        :formOpts="formOpts"
        :listTypeInfo="listTypeInfo"
        :widthSize="2"
      >
      </t-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </t-dialog>

  </div>
</template>
<script>
import { commonMixin } from '@/mixins'
import { delUndefined } from '@/utils/efeichong'

export default {
  mixins: [commonMixin],
  name: "FilterData",
  data() {
    return {
      title: '筛选内容管理',
      url: {
        list: '/gameAccountGoodsFilters/list/',
        getInfo: '/gameAccountGoodsFilters/',
        add: '/gameAccountGoodsFilters/',
        edit: '/gameAccountGoodsFilters/',
        del: '/gameAccountGoodsFilters/'
      },
      searchOpts: [
        { label: '名称', prop: 'label', element: 'el-input', likeType: 'contains' },
        { label: '创建时间', prop: 'createTime', element: 'el-date-picker' },
      ],
      listTypeInfo: {
        // categoryList: [],
      },
      sortOptions: {
        createTime: 'desc'
      },
      queryParams: {
        groupId: null,
      },
      formOpts: {
        title: '下拉框信息',
        ref: null,
        labelWidth: '120px',
        formData: {
          id: null,
          label: null,
          groupId: null,
          sortNo: null,
        },
        fieldList: [
          // { label: '所属分类', value: 'categoryId', type: 'select-arr', comp: 'el-select', list: 'categoryList', widthSize: 1 },
          { label: '名称', value: 'label', comp: 'el-input', widthSize: 1 },
          { label: '排序', value: 'sortNo', type: 'number', comp: 'el-input', widthSize: 1 },
        ],
        rules: {
          label: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          sortNo: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
        }
      },
      table: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        data: [],
        firstColumn: { type: 'selection' },
        operator: [
          {
            text: '编辑',
            fun: this.handleUpdate
          },
          {
            text: '删除',
            fun: this.handleDelete
          }
        ]
      },
      columns: [
        { prop: 'id', label: 'ID', minWidth: '100', sort: true},
        { prop: 'gameInfoName', label: '所属游戏', minWidth: '180'},
        { prop: 'groupName', label: '所属分类', minWidth: '180' },
        { prop: 'label', label: '名称', minWidth: '220', sort: true},
        { prop: 'sortNo', label: '排序', minWidth: '50'},
        { prop: 'createTime', label: '创建日期', minWidth: '220'}
      ]
    }
  },
  created() {
    const groupId = this.$route.params && this.$route.params.filterId;
    this.queryParams.groupId = groupId;
  },
  methods: {
    customhandleAdd() {
      this.bannerProjectId=null
      this.messageProjectId=null
      this.isUpdate = false
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data().formOpts.formData
      )
      this.formOpts.formData.groupId = this.queryParams.groupId;
      this.open = true
      this.title = '新增'
    },
    /** 搜索 */
    customOnSearch(val) {
      this.queryParams.pageIndex = 1
      this.table.currentPage = 1
      const groupId = this.queryParams.groupId;
      this.queryParams = delUndefined(val)
      this.queryParams.groupId = groupId;
      this.getList()
    },
    /** 重置按钮操作 */
    customOnReset() {
      this.table.currentPage=1
      this.customOnSearch({})
    },
  }
}
</script>
