var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("Search", {
        attrs: { searchOpts: _vm.searchOpts, listTypeInfo: _vm.listTypeInfo },
        on: { onSearch: _vm.customOnSearch, onReset: _vm.customOnReset },
      }),
      _c("TableToolBar", {
        attrs: {
          url: _vm.url,
          single: _vm.single,
          multiple: _vm.multiple,
          handleAdd: _vm.customhandleAdd,
          handleDelete: _vm.handleDelete,
        },
      }),
      _c(
        "div",
        { staticClass: "t-table", staticStyle: { width: "100%" } },
        [
          _c("t-table", {
            attrs: {
              table: _vm.table,
              isShowPagination: "",
              columns: _vm.columns,
              listTypeInfo: _vm.listTypeInfo,
            },
            on: {
              "selection-change": _vm.selectionChange,
              "page-change": _vm.pageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "image",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c("img", {
                      attrs: {
                        src: scope.row.image,
                        width: "50",
                        height: "50",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "t-dialog",
        {
          attrs: {
            title: _vm.formOpts.title,
            visible: _vm.open,
            width: "950px",
            isShowDialogDrag: true,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("t-form", {
            attrs: {
              "ref-obj": _vm.formOpts.ref,
              formOpts: _vm.formOpts,
              listTypeInfo: _vm.listTypeInfo,
              widthSize: 2,
            },
            on: {
              "update:refObj": function ($event) {
                return _vm.$set(_vm.formOpts, "ref", $event)
              },
              "update:ref-obj": function ($event) {
                return _vm.$set(_vm.formOpts, "ref", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "image",
                fn: function () {
                  return [
                    _c("t-upload-file", {
                      ref: "upload-file",
                      attrs: {
                        totalLimit: 1,
                        fileType: ".jpg,.jpeg,.png,.webp",
                        isPreview: true,
                        "show-file-list":
                          _vm.formOpts.formData.image != null
                            ? [_vm.formOpts.formData.image]
                            : [],
                      },
                      on: { changeFileList: _vm.changeFileList },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }