<template>
  <div class="app-container">


    <Search :searchOpts="searchOpts" :listTypeInfo="listTypeInfo" @onSearch="customOnSearch" @onReset="customOnReset"/>


    <TableToolBar :url="url" :single="single" :multiple="multiple" :handleAdd="customhandleAdd"
                  :handleDelete="handleDelete"
    />

    <div class="t-table" style="width:100%;">
      <t-table :table="table"
               isShowPagination
               :columns="columns"
               :listTypeInfo="listTypeInfo"
               @selection-change="selectionChange"
               @page-change="pageChange"
      >
      <template #reviewStarsSlot="{scope}">
          <el-rate disabled v-model="scope.row.stars" :colors="colors"></el-rate>
        </template>
      </t-table>
    </div>


    <t-dialog :title="formOpts.title" :visible.sync="open" width="950px" :isShowDialogDrag="true"  append-to-body>
      <t-form
        :ref-obj.sync="formOpts.ref"
        :formOpts="formOpts"
        :listTypeInfo="listTypeInfo"
        :widthSize="2"
      >
      </t-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </t-dialog>

  </div>
</template>
<script>
import { commonMixin } from '@/mixins'
import { delUndefined } from '@/utils/efeichong'

export default {
  mixins: [commonMixin],
  name: "Gamegoodsreviews",
  data() {
    return {
      title: '商品评论',
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      url: {
        list: '/userReviews/list/',
        getInfo: '/userReviews/',
        add: '/gameAccountGoods/evaluate',
        edit: '/userReviews/',
        del: '/userReviews/'
      },
      searchOpts: [
        { label: '创建时间', prop: 'createTime', element: 'el-date-picker' },
        { label: '星级', prop: 'stars', element: 'el-select', list: 'startList' },
      ],
      listTypeInfo: {
        // categoryList: [],
        startList:[
          { dictLabel: '0星', dictValue: 0 },
          { dictLabel: '1星', dictValue: 1 },
          { dictLabel: '2星', dictValue: 2 },
          { dictLabel: '3星', dictValue: 3 },
          { dictLabel: '4星', dictValue: 4 },
          { dictLabel: '5星', dictValue: 5 }
        ],
      },
      sortOptions: {
        createTime: 'desc'
      },
      queryParams: {
        goodsId: null,
      },
      formOpts: {
        title: '商品评论信息',
        ref: null,
        labelWidth: '120px',
        formData: {
          id: null,
          content: null,
          evaluateTime: null,
          goodsId: null,
          stars: null,
          uid: null
        },
        fieldList: [
          { label: '用户UID', value: 'uid', comp: 'el-input', widthSize: 1 },
          { label: '评价时间', value: 'evaluateTime', comp: 't-date-picker',bind: { type: 'datetime' }, widthSize: 1},
          { label: '评价星级', value: 'stars', type: 'number', comp: 'el-rate', widthSize: 1},
          { label: '评价内容', value: 'content', type: 'textarea', comp: 'el-input', widthSize: 1},
        ],
        rules: {
          uid: [{ required: true, message: '请输入用户UID', trigger: 'blur' }],
          content: [{ required: true, message: '请输入评价内容', trigger: 'blur' }],
          evaluateTime: [{ required: true, message: '请输入评价日期', trigger: 'blur' }],
          stars: [{ required: true, message: '请输入评价星级', trigger: 'blur' }],
        }
      },
      table: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        data: [],
        firstColumn: { type: 'selection' },
        operator: [
          {
            text: '删除',
            fun: this.handleDelete
          }
        ]
      },
      columns: [
        { prop: 'id', label: 'ID', minWidth: '100'},
        { prop: 'nickName', label: '用户昵称', minWidth: '180'},
        {
          prop: 'stars',
          label: '评价星级',
          minWidth: '220',
          slotName: 'reviewStarsSlot'
        },
        { prop: 'reviewContent', label: '评价内容', minWidth: '300' },
        { prop: 'createTime', label: '创建日期', minWidth: '220'}
      ]
    }
  },
  created() {
    const goodsId = this.$route.params && this.$route.params.goodsId;
    this.queryParams.goodsId = goodsId;
  },
  methods: {
    /** 新增按钮操作 */
    customhandleAdd() {
      this.bannerProjectId=null
      this.messageProjectId=null
      this.isUpdate = false
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data().formOpts.formData
      )
      this.formOpts.formData.goodsId = this.queryParams.goodsId;
      this.open = true
      this.title = '新增'
    },
    /** 搜索 */
    customOnSearch(val) {
      this.queryParams.pageIndex = 1
      this.table.currentPage = 1
      const goodsId = this.queryParams.goodsId;
      this.queryParams = delUndefined(val)
      this.queryParams.goodsId = goodsId;
      this.getList()
    },
    /** 重置按钮操作 */
    customOnReset() {
      this.table.currentPage=1
      this.customOnSearch({})
    },
  }
}
</script>
