var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { staticClass: "search-form", attrs: { inline: true } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            model: {
              value: _vm.dateRange,
              callback: function ($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.search } },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
      _c("h2", [_vm._v("统计数据")]),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("选中时间内总销售金额"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.formatMoney(_vm.statisticsData.totalSales))),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("选中时间内总订单数"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.totalOrder)),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("选中时间内总用户总数"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.totalUser)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("选中时间内总访问量"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.totalVisit)),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("选中时间内总退款订单数"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.refundOrder)),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("选中时间内总退款金额"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(
                  _vm._s(_vm.formatMoney(_vm.statisticsData.refundAmount))
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("h2", [_vm._v("今日数据")]),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("今日当前用户访问量"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.todayUserVisit)),
              ]),
              _c("div", { staticClass: "arrow-info" }, [
                _vm.statisticsData.todayUserVisitRing !== 0
                  ? _c("i", {
                      class:
                        _vm.statisticsData.todayUserVisitRing > 0
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down",
                      style: {
                        color:
                          _vm.statisticsData.todayUserVisitRing >= 0
                            ? "red"
                            : "green",
                      },
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.statisticsData.todayUserVisitRing) + "%"),
                ]),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("今日当前新增用户数量"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.todayNewUser)),
              ]),
              _c("div", { staticClass: "arrow-info" }, [
                _vm.statisticsData.todayNewUserRing !== 0
                  ? _c("i", {
                      class:
                        _vm.statisticsData.todayNewUserRing > 0
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down",
                      style: {
                        color:
                          _vm.statisticsData.todayNewUserRing >= 0
                            ? "red"
                            : "green",
                      },
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.statisticsData.todayNewUserRing) + "%"),
                ]),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("今日当前新增订单数"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.todayNewOrder)),
              ]),
              _c("div", { staticClass: "arrow-info" }, [
                _vm.statisticsData.todayNewOrderRing !== 0
                  ? _c("i", {
                      class:
                        _vm.statisticsData.todayNewOrderRing > 0
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down",
                      style: {
                        color:
                          _vm.statisticsData.todayNewOrderRing >= 0
                            ? "red"
                            : "green",
                      },
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.statisticsData.todayNewOrderRing) + "%"),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("今日当前新增销售额"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(
                  _vm._s(_vm.formatMoney(_vm.statisticsData.todayNewSales))
                ),
              ]),
              _c("div", { staticClass: "arrow-info" }, [
                _vm.statisticsData.todayNewSalesRing !== 0
                  ? _c("i", {
                      class:
                        _vm.statisticsData.todayNewSalesRing > 0
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down",
                      style: {
                        color:
                          _vm.statisticsData.todayNewSalesRing >= 0
                            ? "red"
                            : "green",
                      },
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.statisticsData.todayNewSalesRing) + "%"),
                ]),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("今日当前新增退款订单数"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(_vm._s(_vm.statisticsData.todayNewRefundOrder)),
              ]),
              _c("div", { staticClass: "arrow-info" }, [
                _vm.statisticsData.todayNewRefundOrderRing !== 0
                  ? _c("i", {
                      class:
                        _vm.statisticsData.todayNewRefundOrderRing > 0
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down",
                      style: {
                        color:
                          _vm.statisticsData.todayNewRefundOrderRing >= 0
                            ? "red"
                            : "green",
                      },
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.statisticsData.todayNewRefundOrderRing) + "%"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "panel-content" }, [
              _c("div", { staticClass: "panel-top-left" }, [
                _vm._v("今日当前新增退款金额"),
              ]),
              _c("div", { staticClass: "panel-number" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatMoney(_vm.statisticsData.todayNewRefundAmount)
                  )
                ),
              ]),
              _c("div", { staticClass: "arrow-info" }, [
                _vm.statisticsData.todayNewRefundAmountRing !== 0
                  ? _c("i", {
                      class:
                        _vm.statisticsData.todayNewRefundAmountRing > 0
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down",
                      style: {
                        color:
                          _vm.statisticsData.todayNewRefundAmountRing >= 0
                            ? "red"
                            : "green",
                      },
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.statisticsData.todayNewRefundAmountRing) + "%"
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-chart-container" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleTabClick },
            },
            [
              _c("el-tab-pane", { attrs: { label: "销售额" } }, [
                _c("div", {
                  ref: "lineChartAmount",
                  staticStyle: { width: "100%", height: "300px" },
                }),
              ]),
              _c("el-tab-pane", { attrs: { label: "订单数" } }, [
                _c("div", {
                  ref: "lineChartOrder",
                  staticStyle: { width: "100%", height: "300px" },
                }),
              ]),
              _c("el-tab-pane", { attrs: { label: "用户数" } }, [
                _c("div", {
                  ref: "lineChartUser",
                  staticStyle: { width: "100%", height: "300px" },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }